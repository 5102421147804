.fixed-top {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999; /* High z-index to ensure it's above other elements */
}

.content-below-fixed-top {
    padding-top: 100px;
    padding-left: 2%;
    padding-right: 2%;
}