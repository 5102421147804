table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

table td,
table th {
    border: 1px solid #ddd;
    padding: 7px;
}

table tr:nth-child(even) {
    background-color: #f2f2f2;
}

table tr:hover {
    background-color: #ddd;
}

table th {
    margin-top: -2;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #0e2ee4;
    color: white;
    position: sticky;
    top: 90px;
    z-index: 1000
}